<template>
	<a-space direction="vertical">
		<a-card title="查询条件">
			<a-form-model @submit.prevent="onSearch" layout="horizontal" v-bind="{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }">
				<a-row>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="ID"><a-input v-model="query.uid" placeholder="请输入用户ID"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="手机号码/昵称"><a-input v-model="query.mobile" placeholder="请输入手机号码/昵称"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="邀请码"><a-input v-model="query.invCode" placeholder="请输入邀请码"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="用户级别">
							<a-select v-model="query.roleId" placeholder="请选择用户等级" style="width:100%" allow-clear>
								<a-select-option value>全部</a-select-option>
								<a-select-option v-for="(val, key) in ROLES" :key="key" :value="key" v-show="key > 0">{{ val }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="注册时间"><a-range-picker :value="selectedDateRange" @change="onChange" style="width:100%" /></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="升级时间"><a-range-picker :value="selectedUpgradeDateRange" @change="onUpgradeChange" style="width:100%" /></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="上级手机号码"><a-input v-model="query.superior" placeholder="请输入上级手机号码"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="用户余额"><a-input v-model="query.money" placeholder="请输入用户余额"></a-input></a-form-model-item>
					</a-col>

					<a-col :md="8" :sm="24">
						<a-form-model-item label="推荐人手机号"><a-input v-model="query.parent" placeholder="请输入手机号"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="产品名称">
							<a-select v-model="query.productId" placeholder="请选择产品名称" style="width:100%" :allow-clear="true">
								<a-select-option v-for="item in products" :key="item.id" :value="String(item.id)">
									<a-space>
										<a-avatar shape="square" :size="24" :src="item.banner"></a-avatar>
										{{ item.productName }}
									</a-space>
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>

					<a-col :md="8" :sm="24">
						<a-form-model-item :wrapper-col="{ span: 16, offset: 6 }">
							<a-space>
								<a-button type="primary" html-type="submit">查询</a-button>
								<a-button @click="onReset">重置</a-button>
							</a-space>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-card>

		<a-card title="用户管理">
			<a-space slot="extra">
				<a-button type="primary" @click="onExport">导出1</a-button>
				<a-button type="primary" @click="onTemplate">导出2</a-button>
			</a-space>

			<div class="statistics">
				<span>用户总数：{{ lists.total | numberFormat }}</span>
				<span>可提现余额：{{ lists.countMoney }}</span>
				<span>云库存数量：{{ lists.totalGoodsNum }}</span>
			</div>

			<a-table
				:data-source="lists.data"
				:pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }"
				@change="onPageChange"
				:scroll="{ x: 'max-content' }"
			>
				<a-table-column key="id" title="用户信息" data-index="id">
					<template slot-scope="text, record">
						<div v-if="!!text">
							{{ text }}
							<a href="javascript:;" v-clipboard:copy="text" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
							<br />
							{{ record.nickname }}
							<!-- <a href="javascript:;" @click="onUpdateRemark(record)"><a-icon type="form" /></a> -->
							（{{ record.mobile }}
							<a href="javascript:;" v-clipboard:copy="record.mobile" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
							）
						</div>
					</template>
				</a-table-column>
				<a-table-column key="invCode" title="邀请码" data-index="invCode" />
				<a-table-column key="roleIdStr" title="用户级别" data-index="roleIdStr" />
				<a-table-column key="parentId" title="邀请人" data-index="parentId">
					<template slot-scope="text, record">
						<div v-if="!!text">
							{{ text }}
							<a href="javascript:;" v-clipboard:copy="text" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
							<br />
							{{ record.parentName }} （{{ record.parentMobile }}
							<a href="javascript:;" v-clipboard:copy="record.parentMobile" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
							）
						</div>
					</template>
				</a-table-column>
				<a-table-column key="bond" title="保证金" data-index="bond" />
				<a-table-column key="money" title="余额" data-index="money" :sorter="true" :sortOrder="moneyOrder" />
				<a-table-column key="ctime" title="创建时间" data-index="ctime" />
				<a-table-column key="cloudNum" title="云库存" data-index="cloudNum" :sorter="true" :sortOrder="cloudNumOrder" />
				<a-table-column key="action" title="操作" fixed="right">
					<template slot-scope="text, record">
						<a-space>
							<a-button size="small" @click="onView(record)">详情</a-button>
							<!-- <a-button size="small" @click="onEdit(record)">编辑</a-button> -->
						</a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>
	</a-space>
</template>

<script>
import moment from 'moment';
import { ROLES } from '@/utils/constants.js';
import { parseQuery, numberFormat } from '@/utils';
const formatter = 'YYYY-MM-DD';

const DEFAULT_QUERY = {
	page: 1,
	size: 10,
	invCode: undefined,
	mobile: undefined,
	beginTime: undefined,
	endTime: undefined,
	beginUpgradeTime: undefined,
	endUpgradeTime: undefined,
	roleId: undefined,
	uid: undefined,
	superior: undefined,
	orderByType: undefined,
	money: undefined,
	goodsNum: undefined,
	productId: undefined
};

export default {
	data() {
		return {
			products: [],
			query: Object.assign({}, DEFAULT_QUERY),
			lists: {
				data: [],
				current_page: 1,
				per_page: 10,
				total: 0
			},
			ROLES
		};
	},
	computed: {
		selectedDateRange() {
			const { beginTime, endTime } = this.query;
			if (beginTime && endTime) {
				return [moment(beginTime, formatter), moment(endTime, formatter)];
			} else {
				return null;
			}
		},
		selectedUpgradeDateRange() {
			const { beginUpgradeTime, endUpgradeTime } = this.query;
			if (beginUpgradeTime && endUpgradeTime) {
				return [moment(beginUpgradeTime, formatter), moment(endUpgradeTime, formatter)];
			} else {
				return null;
			}
		},
		cloudNumOrder() {
			const { orderByType } = this.query;
			if (orderByType == 1) {
				return 'descend';
			} else if (orderByType == 2) {
				return 'ascend';
			}
			return false;
		},
		moneyOrder() {
			const { orderByType } = this.query;
			if (orderByType == 3) {
				return 'descend';
			} else if (orderByType == 4) {
				return 'ascend';
			}
			return false;
		}
	},
	async created() {},
	async mounted() {
		const response = await this.$api.get('/user_query_product/1');
		this.products = (response && response.data) || [];
		Object.assign(this.query, this.$route.query);
		this.getUsers();
	},
	filters: {
		numberFormat
	},
	methods: {
		async getUsers() {
			const response = await this.$api.get('/user_list', {
				params: this.query
			});
			if (response && response.code == 200) {
				response.data.total = parseInt(response.data.total);
				this.lists = Object.assign(this.lists, response.data);
				location.hash = this.$route.path + '?' + parseQuery(this.query);
			}
		},
		async onPageChange(pagination, filters, sorter) {
			if (pagination.current == this.query.page) {
				if (sorter.field == 'cloudNum') {
					if (sorter.order == 'descend') {
						this.query.orderByType = 1;
					} else if (sorter.order == 'ascend') {
						this.query.orderByType = 2;
					} else {
						this.query.orderByType = undefined;
					}
				} else if (sorter.field == 'money') {
					if (sorter.order == 'descend') {
						this.query.orderByType = 3;
					} else if (sorter.order == 'ascend') {
						this.query.orderByType = 4;
					} else {
						this.query.orderByType = undefined;
					}
				} else {
					this.query.orderByType = undefined;
				}
				pagination.current = 1;
			}

			this.query.page = pagination.current;
			this.query.size = pagination.pageSize;
			this.getUsers();
		},
		async onSearch() {
			this.query.page = 1;
			this.getUsers();
		},
		async onReset() {
			this.query = Object.assign({}, DEFAULT_QUERY);
			this.getUsers();
		},
		async onChange(o, dateStr) {
			if (dateStr.length === 2) {
				this.query.beginTime = dateStr[0];
				this.query.endTime = dateStr[1];
			} else {
				this.query.beginTime = undefined;
				this.query.endTime = undefined;
			}
		},
		async onUpgradeChange(o, dateStr) {
			if (dateStr.length === 2) {
				this.query.beginUpgradeTime = dateStr[0];
				this.query.endUpgradeTime = dateStr[1];
			} else {
				this.query.beginUpgradeTime = undefined;
				this.query.endUpgradeTime = undefined;
			}
		},
		async onReject(record) {
			this.modal.data = record;
			this.modal.text = '';
			this.modal.visible = true;
		},
		async onAudit(id, status, remark = '') {
			var response = await this.$api.post('/withdrawal_examine', {
				id,
				status,
				payDesc: remark
			});
			if (response && response.code == 200) {
				this.getUsers();
				this.$message.success('操作成功!');
			}
		},
		async onView(record) {
			this.$router.push(`/users/${record.id}`);
		},
		async onEdit(record) {
			this.$router.push(`/users/${record.id}/edit`);
		},
		async onTemplate() {
			this.$store.dispatch('setLoading', true);
			const response = await this.$api.get('/user_export', {
				params: { ...this.query, exportType: 2 },
				responseType: 'arraybuffer'
			});
		},
		async onExport() {
			this.$store.dispatch('setLoading', true);
			const response = await this.$api.get('/user_export', {
				params: { ...this.query, exportType: 1 },
				responseType: 'arraybuffer'
			});
		},
		async onUpdateRemark(record) {
			var value = record.nickname;
			this.$confirm({
				title: '用户备注昵称',
				content: h => {
					return h('a-input', {
						props: {
							defaultValue: value,
							placeholder: '请输入修改用户显示昵称'
						},
						on: {
							input: e => {
								value = e.target.value;
							}
						}
					});
				},
				onOk: async () => {
					value = value.replace(/^\s|\s$/g, '');
					console.info('value', value);
					if (value == record.nickname) {
						this.$message.success('操作成功!');
						return true;
					}
					if (value) {
						var response = await this.$api.post('/user_remark', {
							uid: record.id,
							remark: value
						});
						console.info('response', response);
						if (response && response.code == 200) {
							this.getUsers();
							this.$message.success('操作成功!');
						} else {
							this.$message.error(response.msg);
						}
						return true;
					} else {
						this.$message.error('请输入修改用户显示昵称');
					}
					return Promise.reject();
				}
			});
		}
	}
};
</script>
